import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject } from 'rxjs';
import { DataProductV2Service } from './api/data-product-v2.service';
import { DataServiceService } from './api/data-service.service';
import { PowerbiServiceService } from './api/powerbi-service.service';


import { Constants } from './helpers/messages';
import { UtilService } from './services/util.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private loginStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private profilePic: BehaviorSubject<any> = new BehaviorSubject<any>('');

  profileImage: string | ArrayBuffer = '';

  constructor(
    public snackBar:MatSnackBar,
    public helperService: UtilService,
    private router: Router,
    private msalService: MsalService,
    public dataService: DataServiceService,
    public productService : DataProductV2Service,
    public powerbiService : PowerbiServiceService,
    private dialog: MatDialog
    
  ) { 
    this.checkLoginStatus()
  }
  
  setLoginStatus(val: boolean): void {
    this.loginStatus.next(val)
  }



  logoutUser(){
    localStorage.clear()
    this.loginStatus.next(false)
    this.router.navigateByUrl('/');
  }

  loginObserverable(){
    return this.loginStatus.asObservable()
  }

  idleLogout(){
    var t: any;
    var route = this.router
    var context = this
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;
    window.onkeypress = resetTimer;
    window.addEventListener('scroll', resetTimer, true);

    function logout(){

      context.dialog.closeAll();
      context.setLoginStatus(false);
      context.snackBar.open('SESSION EXPIRIED, LOGIN TO CONTINUE', "OK", {
        duration: 8000
      })
      route.navigate(['/']);
      localStorage.clear();
    }

    function resetTimer(){
      clearTimeout(t);
      t = setTimeout(logout, 1000 * 60 * 30) // time is in milliseconds      
    }

  }

  checkLoginStatus() {
    let login =  this.helperService.getFromStore(Constants.LOGIN_USER)
    
    if (login) {
      this.loginStatus.next(true);
      this.idleLogout()   
    }

    else {
      this.loginStatus.next(false);
    }
  }
}


