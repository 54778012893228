import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { UtilService } from '../services/util.service';
import { refreshToken } from '../model/productModel';
import { AppService } from '../app.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../core-app/data-connector/error-dialog/error-dialog.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isRefreshed: boolean = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private app: UtilService, private data: AppService, private dialog: MatDialog) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.app.getUserToken();
    if (!token) {
      // Skip the interceptor for this request
      return next.handle(request);
    }
    if (this.isExcludedUrl(request.url)) {
      return next.handle(request);
    }
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.getRefresh(request, next);
        }
        return throwError(() => error);
      })
    );
  }

  private isExcludedUrl(url: string): boolean {
    // Define the URLs that don't require the token here
    const excludedUrls = [
      'https://hooks.slack.com/services/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',
      'api/v1/reports/send-report/'
      // Add more excluded URLs if needed
    ];

    return excludedUrls.some(excludedUrl => url.includes(excludedUrl));
  }

  getRefresh(request: HttpRequest<any>, next: HttpHandler) {
    let payload = new refreshToken();
    payload.userRefreshToken = this.data.helperService.getRefreshUserToken();
  
    if (!this.isRefreshed) {
      this.isRefreshed = true;
      return this.data.productService.getRefreshToken(payload).pipe(
        switchMap((res: any) => {
          this.isRefreshed = false;
          let temp = JSON.parse(localStorage.getItem('activeUser') || '');
          temp.userToken = res['tokens'];
          let finalTemp = JSON.stringify(temp);
          localStorage.setItem('activeUser', finalTemp);
  
          this.refreshTokenSubject.next(res['tokens'].accessTkn);
  
          let token = res.tokens.accessTkn;
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });

          return next.handle(request);

        
        }),
        catchError((err) => {
          this.isRefreshed = false;
        
          if (err instanceof HttpErrorResponse && err.status === 401) {
           
            // Access token and refresh token both expired, redirect to login
            this.data.logoutUser();
            
            // this.data.router.navigate(['/login']);
          } else {
            // Handle other errors
            return throwError(() => err);
          }

          return throwError(() => err);
        })
      );
    } else {
      let token = this.data.helperService.getUserToken();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.data.logoutUser();
      this.errorDialog('SESSION EXPIRIED, LOGIN TO CONTINUE');
  
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap(() =>  next.handle(request))
      );
    }
  }


  errorDialog(item: any) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '30%';
    dialogConfig.data = item;
    this.dialog
      .open(ErrorDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {});
  }
}